export default defineAppConfig({
  productRelationshipLoaderPlacement: {
    dataLayerName: "dataLayerV2",
    tenant: "RO",
    placementRequestTimeout: 2e3,
    placementProductDetailLimit: 16,
    placementCategoryPageLimit: 16,
    placementHomepageLimit: 16,
    placementPreCartLimit: 16,
    placementCartLimit: 16,
    placementELeaderLimit: 1,
    placementTopLimit: 3,
    placementHeroLimit: 1,
    placementBannerWidgetSliderLimit: 12,
    placementBannerWidgetLimit: 3,
    placementCategoryBannerLimit: 1
  }
});