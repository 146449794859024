import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  features: {
    addressFromLocalStorageEnabled: true,
    addressValidationInRegistrationEnabled: true,

    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL', 'SMS'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: false,
    birthDateEnabled: true,
    birthDateOverEnabled: 18,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: true,
    companyItInvoicingEnabled: false,

    competitionNoticeEnabled: false,
    competitionService: {
      competitionImagePath: '',
    },

    correspondenceEmailEnabled: false,
    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: false,
    discountEnabled: true,

    favoriteFilterCategoriesIds: [2680, 3010, 3060, 3219, 3220, 3170, 3385, 3157, 3458, 3419, 3464, 3490, 4399, 2677],

    favoritePharmaciesEnabled: true,
    favoriteProductsEnabled: true,
    favoritesV2Enabled: false,

    returnHygienicAgreementEnabled: false,

    loyaltyCardBackImagePath: '/images/loyalty-card/ro-loyalty-card-back.png',
    loyaltyCardCodeLengthCheckEnabled: true,
    loyaltyCardImagePath: '/images/loyalty-card/ro-loyalty-card.png',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: false,

    cardCodeToOtpModeEnabled: true,
    loyaltyCardNumberDigits: 13,
    loyaltyCardNumberStaticPrefix: '',
    loyaltyProgramBenefitsCount: 5,
    myAccountSorpFromCustomerServiceEnabled: true,
    standAloneCardModeEnabled: false,
    mktPpdUnified: false,
    zipCodeRequired: false,
    checkPersonalDetailsCityFieldEnabled: true,
    accountInfoSorpUpgradeEnabled: false,

    phoneNumberRequired: true,
    phoneNumberSorpCheckEnabled: true,
    phoneCodeEnabled: true,

    priceEnabled: false,
    priceSummaryEnabled: true,
    registrationAddressEnabled: false,
    removeUserEnabled: true,
    returnAndComplaintTitleSplitEnabled: true,
    returnIbanFieldEnabled: true,
    showNotificationSettings: true,

    sexEnabled: true,
    sexThirdOptionEnabled: true,
    sexRequiredEnabled: true,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: false,
    ordersFilterFromEshopEnabled: false,

    emailRestrictedDomainEnabled: envBool(process.env.EMAIL_RESTRICTED_DOMAIN_ENABLED, false),

    surveyEnabled: false,
    userFillableDetails: {
      pregnant: true,
      breastFeeding: true,
      allergies: true,
      treatments: true,
      height: true,
      weight: true,
    },
    emailWhisperer: {
      enabled: true,
      topLevelDomains: ['com', 'cz', 'sk', 'pl', 'it', 'org', 'ro'],
      domains: ['yahoo.ro', 'hotmail.ro', 'mail.ru', 'yahoo.com', 'gmail.com', 'yahoo.ro', 'outlook.com', 'aol.com', 'roadrunner.com', 'zoho.com', 'protonmail.com', 'icloud.com'],
      secondLevelDomains: [],
    },
  },
}
