import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.starts-with.js";
function allowedCharacters(value) {
  return /^[a-z\u0103\u00E2\u00EE\u0219\u021B\s]*$/i.test(value);
}
export default defineAppConfig({
  validations: {
    cityAllowedCharacters: function cityAllowedCharacters(value) {
      return /^[a-z\u0103\u00E2\u00EE\u0219\u015f\u021B\u0163\u002E\u002C\u002D\s\d]*$/i.test(value);
    },
    cityContainsLetter: function cityContainsLetter() {
      return true;
    },
    cityMaxLength: 150,
    companyNameMaxLength: 150,
    crnMaxLength: 16,
    crnNumbersOnly: function crnNumbersOnly() {
      return true;
    },
    crnValid: function crnValid(value) {
      return /^[JCF][0-9]{1,2}\/[0-9]{1,7}\/[0-9]{4}$/.test(value);
    },
    customerNotesAllowedCharacters: function customerNotesAllowedCharacters(value) {
      return allowedCharacters(value);
    },
    emailMaxLength: 150,
    firstLastNameAllowedCharacters: function firstLastNameAllowedCharacters(value) {
      return /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value) || allowedCharacters(value);
    },
    firstNameMaxLength: 150,
    forbiddenWords: [],
    lastNameMaxLength: 150,
    passwordForbiddenKeywords: ["parola"],
    postCodeValid: function postCodeValid(value) {
      return /^[0-9]{6}$/.test(value);
    },
    postCodeValidByForbiddenCode: function postCodeValidByForbiddenCode() {
      return true;
    },
    postCodeValidByThreeDigits: function postCodeValidByThreeDigits() {
      return true;
    },
    streetAllowedCharacters: function streetAllowedCharacters(value) {
      return /^[a-z\u0103\u00E2\u00EE\u0219\u015f\u021B\u0163\u002E\u002C\u002D\s\d]*$/i.test(value);
    },
    streetMaxLength: 150,
    streetNumberContainsNumber: function streetNumberContainsNumber() {
      return true;
    },
    streetNumberValid: function streetNumberValid() {
      return true;
    },
    streetValid: function streetValid() {
      return true;
    },
    vatIdForbiddenValues: ["9378655", "RO9378655"],
    vatIdForbiddenWords: function vatIdForbiddenWords(value) {
      return !["9378655", "RO9378655"].includes(value);
    },
    vatIdMinLength: 6,
    vatIdMaxLength: 10,
    vatIdMaxLengthValid: function vatIdMaxLengthValid(value) {
      var numericVatId = value.startsWith("RO") ? value.substring(2) : value;
      return numericVatId.length <= 10;
    },
    vatIdMinLengthValid: function vatIdMinLengthValid(value) {
      var numericVatId = value.startsWith("RO") ? value.substring(2) : value;
      return numericVatId.length >= 6;
    },
    vatIdStartsWith: function vatIdStartsWith(value) {
      return !/^[0-9]+/.test(value) ? value.startsWith("RO") : true;
    },
    vatIdValid: function vatIdValid(value) {
      var numericVatId = value.startsWith("RO") ? value.substring(2) : value;
      return /^\d{6,10}$/.test(numericVatId);
    }
  }
});