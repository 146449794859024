export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      'pickup_place_cc': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_payment_complete.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_payment_complete.text',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_packed',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_packed.label',
          text: '',
          variant: 'success',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.shipping.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.shipping.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.ready_for_pickup.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.ready_for_pickup.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_expired.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_expired.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'pickup_place_box': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_payment_complete.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_payment_complete.text',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.shipping.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.shipping.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.ready_for_pickup.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.ready_for_pickup.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'delivery': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_payment_complete.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_payment_complete.text',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_packed',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_packed.label',
          text: '',
          variant: 'success',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.shipping.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.shipping.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'delivery_big-format': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.inprogress.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.inprogress.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: '', // such status won't be used
          text: '', // such status won't be used
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: '', // such status won't be used
          text: '', // such status won't be used
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.shipping.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.shipping.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.ready_for_pickup.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.ready_for_pickup.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_big-format.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
      'reservation': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress_payment_complete.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress_payment_complete.text',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.ready_for_pickup.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.ready_for_pickup.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled_expired.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled_expired.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'pharmacy_purchase': [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.new.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.new.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.delivered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.delivered.text',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
