const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsShippingText1' },
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsShippingText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsSmsText1' },
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsSmsText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.innoshipBox.pickupInstructionsCalendarText2',
        isBold: true,
      },
      { text: 'pickupSelect.config.innoshipBox.pickupInstructionsCalendarText3' },
    ],
  },
]

export default {
  orderLabel: 'pickupSelect.config.innoshipBox.orderLabel',
  unavailablePickupLabel: 'pickupSelect.config.innoshipBox.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.innoshipBox.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.innoshipBox.confirmButtonLabel',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-easybox-enabled.svg',
    disabled: 'pin-easybox-disabled.svg',
    selected: 'pin-easybox-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/box.svg',
}
