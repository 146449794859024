import { ORDER_TYPE } from '../../utils/Utils.js'

const orderPickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.orderPickupInstructionsShippingText1' },
      {
        text: 'pickupSelect.config.pharmacy.orderPickupInstructionsShippingText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'pickupSelect.config.pharmacy.orderPickupInstructionsSmsText1',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.orderPickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.pharmacy.orderPickupInstructionsCalendarText2',
        isBold: true,
      },
    ],
  },
]

const reservationPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.reservationPickupInstructionsPharmacyText1' },
      {
        text: 'pickupSelect.config.pharmacy.reservationPickupInstructionsPharmacyText2',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'pickupSelect.config.pharmacy.reservationPickupInstructionsSmsText1',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'pickupSelect.config.pharmacy.reservationPickupInstructionsCalendarText1' },
      {
        text: 'pickupSelect.config.pharmacy.reservationPickupInstructionsCalendarText2',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'pickupSelect.config.pharmacy.orderLabel',
  reservationLabel: 'pickupSelect.config.pharmacy.reservationLabel',
  rxReservationLabel: 'pickupSelect.config.pharmacy.rxReservationLabel',
  unavailablePickupLabel: 'pickupSelect.config.pharmacy.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.pharmacy.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.pharmacy.confirmButtonLabel',
  inStockLabel: 'pickupSelect.config.pharmacy.inStockLabel',
  onOrderLabel: 'pickupSelect.config.pharmacy.onOrderLabel',
  rxReservationButtonLabel: 'pickupSelect.config.pharmacy.rxReservationButtonLabel',
  rxOrderButtonLabel: 'pickupSelect.config.pharmacy.rxOrderButtonLabel',
  getTitle() {
    return `Dr. Max Farmacie, ${this.title}`
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions(orderType) {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: (() => {
        switch (orderType) {
          case ORDER_TYPE.ORDER:
            return orderPickupInstructions
          case ORDER_TYPE.RESERVATION:
            return reservationPickupInstructions
          default:
            break
        }

        return []
      })(),
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
