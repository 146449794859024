const pickupInstructions = []

export default {
  orderLabel: 'pickupSelect.config.orderLabel',
  reservationLabel: 'pickupSelect.config.reservationLabel',
  rxReservationLabel: 'pickupSelect.config.rxReservationLabel',
  unavailablePickupLabel: 'pickupSelect.config.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.confirmButtonLabel',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/box.svg',
}
